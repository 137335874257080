const tableBodyRowStyles = (theme, {onRowClick, closeAdditionalInfo, row, additionalRowPropName, rowIndex, redDelete}) => {

    return {
        row: {
            cursor: onRowClick || closeAdditionalInfo ? "pointer" : null,
            background:
                // (redDelete && redDelete.includes(row.id)) ? "rgba(224, 23, 23, 0.3)":
                row.dataCheckingStatus === 1
                    ? "rgba(22,176,37,0.2)"
                    : row.dataCheckingStatus === 2
                    ? "rgba(224,23,23,0.25)"
                    : row.dataCheckingStatus === 3 || row.totalLast
                    ? "rgba(224,96,23,0.3)"
                    : additionalRowPropName || rowIndex % 2
                    ? "rgba(217, 217, 217, 0.20)"
                    : "#FFF",
            "&:hover": {
                backgroundColor: onRowClick || closeAdditionalInfo ? "rgba(93, 175, 239, 0.20)" : null,
                [theme.breakpoints.down("sm")]: {
                    backgroundColor: "inherit"
                }
            }
        }
    }
}

export default tableBodyRowStyles
