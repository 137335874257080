import React from 'react'
import { observer } from 'mobx-react'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { getStyles } from 'src/utils/utils/commonUtils'
import customTableStyles from 'src/utils/components/CustomTable/styles/customTableStyles'
import TableHeadCell from 'src/utils/components/CustomTable/components/CustomTableHead/TableHeadCell'
import { isDealer } from '../../../../../services/AccountService'

function CustomTableHead({ columns, headCheackbox }) {
    const classes = getStyles(customTableStyles)
    return (
        <TableHead
            sx={
                isDealer()
                    ? {
                          ...classes.tableRow,
                          display: {
                              xs: 'none',
                              sm: 'table-header-group',
                          },
                      }
                    : { ...classes.tableRow }
            }
        >
            <TableRow>
                {columns.map((column, index) => (
                    <TableHeadCell key={index} data={column} headCheackbox={headCheackbox} />
                ))}
            </TableRow>
        </TableHead>
    )
}

export default observer(CustomTableHead)
