import Box from '@mui/material/Box'
import { isLooker } from 'src/services/AccountService'
import CustomPagination from 'src/utils/components/CustomPagination/CustomPagination'
import React, { useEffect, useState } from 'react'
import tableActionsBlockStyles from 'src/utils/components/CustomTable/styles/tableActionBlockStyles'
import { getStyles } from 'src/utils/utils/commonUtils'
import { observer } from 'mobx-react'
import { Fade, Portal } from '@mui/material'
import { isAdmin } from '../../../../../services/AccountService'
import useScrollToggle from 'src/hooks/useScrollToggle'

function TableActionsBlock({
    rows,
    actionsBlock,
    showPagination,
    total,
    pageSize,
    setInputValue,
    inputValue,
    page,
    rowsPerPageOptions,
    onPageSizeChange,
    onPageChange,
    doublePagination,
    children,
    additionalRowPropName,
    hideUninformative,
    setNewRowsFront,
    newRowsFront,
    frontendPagination,
}) {
    const classes = getStyles(tableActionsBlockStyles)
    let timeoutId

    useEffect(() => {
        setInputValue(page + 1)
    }, [page])

    const frontPagination = frontendPagination
    const [pageFront, setPageFront] = React.useState(0)
    const [rowsPerPageFront, setRowsPerPageFront] = React.useState(100)
    const [inputPage, setInputPage] = React.useState(pageFront + 1)

    const newRows = rows.slice(pageFront * rowsPerPageFront, pageFront * rowsPerPageFront + rowsPerPageFront)
    useEffect(() => {
        setNewRowsFront(newRows)
    }, [pageFront, rowsPerPageFront, newRows.length, setNewRowsFront, pageFront])
    const onChange = (e) => {
        if (
            e.target.value <= Math.ceil(total / pageSize) &&
            ((!!Number(e.target.value) && Number(e.target.value) >= 0) || e.target.value === '')
        ) {
            setInputValue(e.target.value)
            if (timeoutId) {
                clearTimeout(timeoutId)
                timeoutId = null
            }
            if (!!e.target.value && +e.target.value !== page + 1) {
                timeoutId = setTimeout(() => {
                    onPageChange(e, e.target.value - 1)
                }, 1000)
            }
        }
    }

    const [mounted, setMounted] = React.useState(false)
    const mountedRef = React.useRef(false)

    useEffect(() => {
        if (mountedRef.current) {
            handleChangePageFront(null, 0)
        } else {
            mountedRef.current = true
        }
    }, [total])

    useEffect(() => {
        setMounted(true)
    }, [])

    const handleChangePageFront = (event, newPage) => {
        setPageFront(newPage)
        setInputPage(newPage + 1)
    }

    const handleChangeRowsPerPageFront = (event) => {
        setRowsPerPageFront(+event.target.value)
        setPageFront(0)
        setInputPage(1)
    }

    const handleInputPageChange = (event) => {
        const newPage = event.target.value ? Number(event.target.value) - 1 : 0
        setInputPage(event.target.value)
        if (newPage >= 0 && newPage < totalPagesFront) {
            setPageFront(newPage)
        }
    }

    const totalPagesFront = Math.ceil(rows.length / rowsPerPageFront)

    const scroll = isAdmin() && window.location.pathname === '/requests' ? useScrollToggle(200, 350) : false

    const style =
        isAdmin() && scroll
            ? {
                  position: 'fixed',
                  top: '24px',
                  zIndex: 100,
                  paddingX: '72px',
                  '@media (max-width: 1440px)': {
                      paddingX: '32px',
                  },
              }
            : null

    return (
        <>
            <Box sx={{ height: actionsBlock?'72px': null }}>
                {(doublePagination && rows.length > 19) || (!!total && actionsBlock) ? (
                    scroll ? (
                        <>
                            <Portal>
                                <Fade in={scroll}>
                                    <Box sx={{ ...classes.actionsContainer, ...style }}>
                                        <Box
                                            sx={{
                                                backgroundColor: '#fff',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                padding: '16px',
                                                boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.15)',
                                            }}
                                        >
                                            <Box>{!!total && !isLooker() && actionsBlock}</Box>

                                            {doublePagination && rows.length > 19 ? (
                                                <CustomPagination
                                                    showPagination={showPagination}
                                                    total={frontPagination ? total : total}
                                                    inputValue={frontPagination ? inputPage : inputValue}
                                                    onChange={frontPagination ? handleInputPageChange : onChange}
                                                    pageSize={frontPagination ? rowsPerPageFront : pageSize}
                                                    rowsPerPageOptions={rowsPerPageOptions}
                                                    page={frontPagination ? pageFront : page}
                                                    onPageChange={
                                                        frontPagination ? handleChangePageFront : onPageChange
                                                    }
                                                    onPageSizeChange={
                                                        frontPagination
                                                            ? handleChangeRowsPerPageFront
                                                            : onPageSizeChange
                                                    }
                                                    hideElementsOnTablets={true}
                                                />
                                            ) : null}
                                        </Box>
                                    </Box>
                                </Fade>
                            </Portal>
                            <Box sx={{ height: '72px' }}></Box>
                        </>
                    ) : (
                        <Box sx={{ ...classes.actionsContainer, ...style }}>
                            <Box>{!!total && !isLooker() && actionsBlock}</Box>

                            {doublePagination && rows.length > 19 ? (
                                <CustomPagination
                                    showPagination={showPagination}
                                    total={frontPagination ? total : total}
                                    inputValue={frontPagination ? inputPage : inputValue}
                                    onChange={frontPagination ? handleInputPageChange : onChange}
                                    pageSize={frontPagination ? rowsPerPageFront : pageSize}
                                    rowsPerPageOptions={rowsPerPageOptions}
                                    page={frontPagination ? pageFront : page}
                                    onPageChange={frontPagination ? handleChangePageFront : onPageChange}
                                    onPageSizeChange={frontPagination ? handleChangeRowsPerPageFront : onPageSizeChange}
                                    hideElementsOnTablets={true}
                                />
                            ) : null}
                        </Box>
                    )
                ) : null}
            </Box>
            {children}
            {!!total && showPagination ? (
                <Box sx={classes.actionsContainer} mt={3.5} pb={1}>
                    <Box>
                        <Box sx={{ ...classes.hideOnTablets, ...classes.hideOnTablets }}>
                            {((!!total && !isLooker() && rows.length > 19) || additionalRowPropName) && actionsBlock}
                        </Box>
                    </Box>
                    <CustomPagination
                        showPagination={showPagination}
                        total={frontPagination ? total : total}
                        inputValue={frontPagination ? inputPage : inputValue}
                        onChange={frontPagination ? handleInputPageChange : onChange}
                        pageSize={frontPagination ? rowsPerPageFront : pageSize}
                        rowsPerPageOptions={rowsPerPageOptions}
                        page={frontPagination ? pageFront : page}
                        onPageChange={frontPagination ? handleChangePageFront : onPageChange}
                        onPageSizeChange={frontPagination ? handleChangeRowsPerPageFront : onPageSizeChange}
                        hideUninformative={hideUninformative}
                        rows={rows}
                    />
                </Box>
            ) : null}
        </>
    )
}

export default observer(TableActionsBlock)
