const filtersFormStyles = (theme, { length, opened }) => ({
    root: {
        display: opened ? 'grid' : 'none',
        gap: '48px',
        position: 'absolute',
        left: 0,
        top: 'calc(100% + 8px)',
        width: '100%',
        zIndex: '1000',
        padding: '24px 60px',
        backgroundColor: theme.palette.gray.light,
        borderRadius: '6px',
        cursor: 'default',
        maxHeight: '75vh',
        // overflowY: "scroll",
        overflowY: 'auto',
        gridTemplateColumns: `${length > 1 ? '1fr' : 'calc(75% - 48px)'} ${length > 1 ? '' : '25%'}`,
        [theme.breakpoints.down('sm')]: {
            display: opened ? 'block' : 'none',
            padding: '8px 24px 48px 24px',
            width: 'calc(100vw - 16px)',
            height: 'calc(100vh - 112px)',
            maxHeight: 'calc(100vh)',
        },
    },
    filters: {
        display: 'grid',
        gap: '0 48px',
        gridTemplateColumns: `repeat(${length}, minmax(100px, 1fr))`,
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
            gridTemplateColumns: '1fr',
        },
    },
    filtersContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
            overflow: 'hidden',
        },
    },
    formFilterWrapper: {
        width: `calc(50% - 8px)`,
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    actionButton: {
        width: '100%',
        maxWidth: '323px',
    },

    filtersBlock: {
        display: 'flex',
        gap: '16px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: length === 1 ? 'column' : 'row',
        gap: '16px',
        justifyContent: length === 1 ? 'flex-start' : 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
        },
    },
})

export default filtersFormStyles
