import React from 'react'
import { DatePicker, DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import TextField from '@mui/material/TextField'
import moment from 'moment'
import MultipleSelect from '../customElements/MultipleSelect/MultipleSelect'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormInput from '../customElements/FormInput'
import selectArrow from '../../assets/images/common/selectArrow.svg'
import { observer } from 'mobx-react'
import lupe from '../../assets/images/common/lupe.svg'
import calendarBlack from '../../assets/images/common/calendar-black.svg'
import 'moment/locale/ru'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { isWidthUp } from '@mui/material/Hidden/withWidth'
function FormFilter({
    data: {
        type,
        name,
        propName,
        collection,
        setMenuItem,
        placeholder,
        multiple,
        minDate,
        maxDate,
        settings,
        visible,
        mb,
        variant,
        defaultMonth,
        containerStyles,
        disabled,
    },
    filtersStore,
    onSearch,
    setChip,
    opened,
    selectsOpen,
    setSelectsOpen,
    territorySelect,
    width,
    data,
}) {
    const setSelectArrow = (e) => <img className={e.className} alt={''} src={selectArrow} />
    const value = filtersStore.data[propName]

    // функция валидации некоторых инпутов. Если инпут не подлежит валидации то возвращается true
    const validateFilterInputs = (event) => {
        if (name === 'Номер заявки' || name === 'Id оборудования') {
            const pattern = /^[0-9]+$/
            return pattern.test(event.target.value) || event.target.value === '' ? true : false
        }

        if (name === 'Телефон') {
            const phonePattern = /^[0-9()-]+$/
            return phonePattern.test(event.target.value) || event.target.value === '' ? true : false
        }

        if (name === 'ФИО') {
            const pattern = /^[Ёа-яА-Яёa-zA-Z\s]+$/
            return pattern.test(event.target.value) || event.target.value === '' ? true : false
        }

        if (name === 'Льготная категория') {
            const pattern = /^[0-9Ёа-яА-Яёa-zA-Z\s]+$/
            return pattern.test(event.target.value) || event.target.value === '' ? true : false
        }

        return true
    }

    if (propName === 'reasonId') {
        collection.map((item) => {
            if (item.key === 'Замена') {
                return (item.key = item.key + ' (Горыныч)')
            }
            return item
        })
    }

    

    return (
        <Box mb={isWidthUp('sm', width) ? mb : null} width={'100%'}>
            {type === 'date' ? (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                        leftArrowButtonText={'Предыдущий месяц'}
                        rightArrowButtonText={'Следующий месяц'}
                        minDate={minDate && minDate}
                        maxDate={maxDate && maxDate}
                        renderInput={({ inputProps, ...props }) => {
                            return (
                                <TextField
                                    {...props}
                                    {...inputProps}
                                    placeholder={'ДД.MM.ГГГГ'}
                                    fullWidth={true}
                                    error={false}
                                />
                            )
                        }}
                        value={value}
                        onChange={(newValue) => {
                            filtersStore.data[propName] = newValue ? moment(newValue) : null
                        }}
                        label={name}
                        inputFormat={'DD.MM.YYYY'}
                        defaultCalendarMonth={defaultMonth}
                        components={{
                            OpenPickerIcon: () => <img alt={'calendar icon'} src={calendarBlack} />,
                        }}
                    />
                </LocalizationProvider>
            ) : type === 'select' ? (
                multiple ? (
                    <MultipleSelect
                        value={value}
                        onChange={(event) => {
                            filtersStore.onChangeMultiple(event)
                        }}
                        onClear={() => {
                            filtersStore.onClearMultiple(propName)
                        }}
                        items={collection}
                        propName={propName}
                        label={name}
                        renderValue={(value) => (
                            <Box
                                sx={{
                                    maxWidth: '100%',
                                    display: 'flex',
                                    flexWrap: 'wrap',

                                    gap: '1px',
                                    overflow: 'hidden',
                                }}
                            >
                                {value.map((multiselectValue) => (
                                    <Box key={propName + multiselectValue}>
                                        {setChip ? setChip({ propName, value: multiselectValue }) : value}
                                    </Box>
                                ))}
                            </Box>
                        )}
                        placeholder={placeholder}
                        IconComponent={setSelectArrow}
                        SelectDisplayProps={{
                            onKeyDown: () => {},
                        }}
                        opened={opened}
                        selectsOpen={selectsOpen}
                        setSelectsOpen={setSelectsOpen}
                        disabled={disabled}
                    >
                        {setMenuItem ? collection.map((el) => setMenuItem(el)) : null}
                    </MultipleSelect>
                ) : (
                    <FormControl disabled={disabled} fullWidth>
                        <InputLabel disabled={disabled}>{name}</InputLabel>
                        <Select
                            value={value}
                            label={name}
                            fullWidth
                            disabled={disabled}
                            IconComponent={disabled ? null : setSelectArrow}
                            renderValue={(value) => (
                                <Box sx={{ maxWidth: '100%', overflow: 'hidden' }}>
                                    {setChip ? setChip({ propName, value }) : value}
                                </Box>
                            )}
                            onChange={(event) => {
                                filtersStore.data[propName] = event.target.value
                            }}
                            SelectDisplayProps={{
                                onKeyDown: () => {},
                            }}
                        >
                            {collection.map((el) => {
                                return setMenuItem ? (
                                    setMenuItem(el)
                                ) : (
                                    <MenuItem value={el} key={el}>
                                        {el}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                )
            ) : (
                <FormInput
                    type={type}
                    api={filtersStore}
                    propName={propName}
                    label={name}
                    fullWidth
                    placeholder={placeholder}
                    settings={settings}
                    visible={visible}
                    variant={variant}
                    territorySelect={data?.territorySelect}
                    InputProps={{
                        endAdornment: <img alt={''} src={lupe} />,
                    }}
                    // validateFilterInputs={validateFilterInputs}
                    containerStyles={containerStyles}
                    autocompleteOptions={data?.autocompleteOptions}
                    defaultValue={data?.defaultValue}
                    disabled={data?.disabled}
                    items={data?.items}
                    mask={data?.mask}
                    multiline={data?.multiline}
                    onChange={data?.onChange}
                    onPaste={data?.onPaste}
                    readOnly={data?.readOnly}
                    styles={data?.styles}
                    // visible={data?.visible}
                    withoutErrorText={data?.withoutErrorText}
                    offError={data?.offError}
                />
            )}
        </Box>
    )
}

export default observer(FormFilter)
