import React from "react"
import {observer} from "mobx-react"
import Typography from "@mui/material/Typography"
import {useTheme} from "@mui/material"
import {useIntl} from "react-intl"

const OrderValue = ({value, active, title}) => {
    const theme = useTheme()
    const intl = useIntl()
    return (
        <Typography
            variant={"subtitle1"}
            sx={{
                color: theme.palette.text.black,
                whiteSpace: "nowrap",
                fontSize: "15px",
                fontWeight: 500
            }}
        >
            {active
                ? intl.formatMessage({id: "Настройки поиска"})
                : title ||
                intl.formatMessage(
                    {
                        id: "Найдено заявок {orderValue}",
                        defaultMessage: "Найдено заявок {orderValue}",
                    },
                    {
                        orderValue: value,
                    }
                )}
        </Typography>
    )
}

export default observer(OrderValue)
