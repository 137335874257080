import moment from 'moment/moment'
import { reportingPeriods } from 'src/helpers/getReportPeriodDate'

export function checkEditCreationDate(date, newDate) {
    const dateMonth = moment(date).format('MM')
    const dateYear = moment(date).format('YYYY')
    const newDateMonth = moment(newDate).format('MM')
    if (Number(dateMonth) <= 9 && dateYear === '2023') {
        return dateMonth === newDateMonth
    } else {
        const dateDay = +moment(date).format('DD')
        const newDateDay = +moment(newDate).format('DD')
        const period = reportingPeriods.find(({ from, to }) => dateDay >= from && dateDay <= to)
        return dateMonth === newDateMonth && period && newDateDay >= period.from && newDateDay <= period.to
    }
}

export function formatPhoneNumber(phoneNumber) {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '')
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/)

    if (match) {
        return `+${match[1]} (${match[2]})-${match[3]}-${match[4]}-${match[5]}`
    }

    return null 
}

export function formatAddress(order, withoutTerritory) {
    const { territory, locality, addressType, address, house, building, apartment, district } = order

    let formatAddress = ''
    if (territory && !withoutTerritory) {
        formatAddress += `${territory}`
    }
    if (district) {
        formatAddress += `, ${district}`
    }
    if (locality) {
        formatAddress += `, ${locality}`
    }
    if (address) {
        formatAddress += `, ${address}`
    }
    if (addressType) {
        formatAddress += ` ${addressType}`
    }
    if (house) {
        formatAddress += `, ${house}`
    }
    if (building) {
        formatAddress += `, ${building}`
    }
    if (apartment) {
        formatAddress += `, ${apartment}`
    }
    return formatAddress
}

export function formatName(order) {
    const { name, surname, patronymic } = order
    let formatName = `${name}`
    if (surname) {
        formatName = `${surname} ` + formatName
    }
    if (patronymic) {
        formatName += ` ${patronymic}`
    }
    return formatName
}

export function formatID(order) {
    return String(order.id).padStart(6, '0')
}

export function convertId(id) {
    return String(id).padStart(6, '0')
}
