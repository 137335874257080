import React, { useEffect } from 'react'
import TableBody from '@mui/material/TableBody'
import TableBodyRow from 'src/utils/components/CustomTable/components/CustomTableBody/TableBodyRow'
import { observer } from 'mobx-react'

function CustomTableBody({
    rows,
    columns,
    onRowClick,
    closeAdditionalInfo,
    additionalRowCells,
    additionalRowPropName,
    total,
    setCurrentOpenedRowIndex,
    currentOpenedRowIndex,
    redDelete,
    toggleIsRowOpen
}) {
    // const [rendererCollection, setRendererCollection] = useState([])
    //
    // const setRenderCollection = () => {
    //     if (rows.length || rendererCollection.length) {
    //         setRendererCollection((prevState) => {
    //             return rows.slice(0, prevState.length + 10)
    //         })
    //     }
    // }
    //
    // useEffect(() => {
    //     setRenderCollection()
    // }, [rows])
    // useLayoutEffect(() => {
    //     if (rendererCollection.length && rendererCollection.length !== rows.length) {
    //         setTimeout(setRenderCollection, 100)
    //     }
    // }, [rendererCollection])

    // при смене индекса текущей строки, открывается дровер с информацией строки с нужным индексом
    useEffect(() => {
        rows.forEach((row, index) => {
            if (currentOpenedRowIndex === index) {
                // onRowClick({ data: row })
            }
        })

        // return () => {
        //     setCurrentOpenedRowIndex(null)
        // }
    }, [currentOpenedRowIndex])
    
    return (
        <>
            <TableBody>
                {rows.map((row, index) => {
                    return (
                        <TableBodyRow
                            key={`${Date.now()}_${index}`}
                            row={row}
                            columns={columns}
                            rowIndex={index}
                            onRowClick={onRowClick}
                            closeAdditionalInfo={closeAdditionalInfo}
                            additionalRowCells={additionalRowCells}
                            additionalRowPropName={additionalRowPropName}
                            total={total}
                            setCurrentOpenedRowIndex={setCurrentOpenedRowIndex}
                            currentOpenedRowIndex={currentOpenedRowIndex}
                            redDelete={redDelete}
                            toggleIsRowOpen={toggleIsRowOpen}
                        />
                    )
                })}
            </TableBody>
        </>
    )
}

export default observer(CustomTableBody)
