import { observer } from 'mobx-react'
import React from 'react'
import TableCell from '@mui/material/TableCell'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import tableHeadCellStyles from 'src/utils/components/CustomTable/styles/tableHeadCellStyles'
import { getStyles } from 'src/utils/utils/commonUtils'
import { Checkbox } from '@mui/material'

function TableHeadCell({ data, headCheackbox }) {
    const classes = getStyles(tableHeadCellStyles, data)
    const { headerElement, propName, titleIcon, headerName, icon } = data
    // const {} = headCheackbox
    

    return headerName !== 'Регион / МО или ГО' ? (
        <TableCell className={'fx-nowrap'} sx={classes.headerCell} align={'left'} onClick={data.handleClick}>
            <Box className={'aln-center jst-center'} sx={classes.contentContainer}>
                {propName === 'checkBox' && headerElement === '' ? (
                    <Checkbox
                        checked={headCheackbox.allChecked ? headCheackbox.allChecked() : undefined}
                        indeterminate={
                            headCheackbox.toggletoggleGroupSelected
                                ? headCheackbox.toggletoggleGroupSelected()
                                : undefined
                        }
                        disabled={headCheackbox.disabled}
                        onChange={headCheackbox.onChange ? headCheackbox.onChange : undefined}
                    />
                ) : null}
                {headerElement ? (
                    headerElement
                ) : propName === 'openRow' ? (
                    ''
                ) : (
                    <>
                        {titleIcon}
                        <Typography variant={'body1'} sx={classes.headerText}>
                            {headerName}
                        </Typography>
                        {icon}
                    </>
                )}
            </Box>
        </TableCell>
    ) : (
        <TableCell  className={'fx-nowrap'} sx={classes.headerCell} align={'left'} onClick={data.handleClick}>
            <Typography variant={'body1'} sx={{...classes.headerText, textAlign: 'left'}}>
                {headerName}
            </Typography>
        </TableCell>
    )
}

export default observer(TableHeadCell)
