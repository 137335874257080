import React from 'react'
import { FormControl, IconButton } from '@mui/material'
import Select from '@mui/material/Select'
import { css } from '@emotion/css'
import MenuItem from '@mui/material/MenuItem'
import { getStyles } from 'src/utils/utils/commonUtils'
import commonStyles from '../../../styles/commonStyles'
import inputFieldStyles from '../InputField/styles/inputFieldStyles'
import InputLabel from '@mui/material/InputLabel'
import { observer } from 'mobx-react'
import closeIcon from '../../../assets/images/common/cross_gray.svg'
import { useEffect } from 'react'

const MultipleSelect = (props) => {
    const classes = { ...getStyles(commonStyles, props), ...getStyles(inputFieldStyles) }

    let clearIconVisible = !!props.value?.length || !!props.items?.length

    const { selectsOpen, setSelectsOpen, propName, opened } = props

    // переменная контролирует добавление контроля селекта
    // если в пропсах не приходят переменные и функция контроля то селект остается неконтролируемым
    let controlSelect = selectsOpen !== undefined && setSelectsOpen !== undefined && opened !== undefined

    //при открытии селекта значение соотвествующего поля в стейте меняется на тру
    const handleOpen = () => {
        setSelectsOpen({ [propName]: true })
    }

    // при закрытии формы фильтров селект соотвествующего поля в стейте меняется на false
    useEffect(() => {
        if (opened) {
            setSelectsOpen({
                [propName]: false,
            })
        }
    }, [opened])

    // при нажатии ентер на открытом селекте блокируется повторный выбор элемента селекта.
    //  Иначе на ентер выбранное поле сбрасывается перед закрытием електа
    const handleOnChange = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            return
        }
        return props.onChange(event)
    }

    return (
        <FormControl fullWidth sx={{ ...props.containerStyle, position: 'relative' }}>
            <InputLabel
                sx={{ color: props.error ? '#EA2020' : props.disabled ? 'rgba(0, 0, 0, 0.38)' : null }}
                id={propName}
            >
                {props.label}
            </InputLabel>
            <Select
                // Три поля для контроля поведения селекта активны тольк если controlSelect = true
                // если в стейте селектов есть соответствующее поле, то этот селект зависит от него
                open={controlSelect ? (selectsOpen[propName] ? selectsOpen : false) : undefined}
                onOpen={controlSelect ? handleOpen : undefined}
                onClose={
                    controlSelect
                        ? () => {
                              setSelectsOpen({ [propName]: false })
                          }
                        : undefined
                }
                name={propName}
                value={props.value}
                onChange={controlSelect ? handleOnChange : props.onChange}
                className={css(classes.selectField, classes.fullWidth)}
                sx={{
                    width: props.width,
                    minWidth: props.minWidth,
                    minHeight: '40px',
                    '& .MuiSelect-select': {
                        paddingRight: clearIconVisible ? '65px !important' : '',
                    },
                }}
                inputProps={{
                    sx: { padding: '6px 14px' },
                }}
                placeholder={props.placeholder}
                multiple={true}
                id={propName}
                IconComponent={props.IconComponent}
                SelectDisplayProps={props.SelectDisplayProps}
                label={props.label}
                renderValue={props.renderValue}
                disabled={props.disabled}
            >
                {props.children
                    ? props.children
                    : props.items.map((item) => {
                          const { color } = item
                          return (
                              <MenuItem
                                  key={item.key ? item.key + item.value : item}
                                  value={item.value || item.value === 0 ? item.value : item}
                                  sx={{
                                      ...classes.menuItem,
                                      background: color ? color : '',
                                  }}
                              >
                                  {item.key ? item.key : item}
                              </MenuItem>
                          )
                      })}
            </Select>

            {clearIconVisible && !!props.onClear && props.value.length ? (
                <IconButton
                    onClick={() => {
                        props.onClear(propName)
                    }}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        right: '35px',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                    }}
                >
                    <img src={closeIcon} alt='clear' />
                </IconButton>
            ) : null}
        </FormControl>
    )
}

export default observer(MultipleSelect)
