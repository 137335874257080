import React from 'react'
import { observer } from 'mobx-react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { FormattedMessage, useIntl } from 'react-intl'
import { TextField } from '@mui/material'
import TablePagination from '@mui/material/TablePagination'
import withWidth from '@mui/material/Hidden/withWidth'
import { css } from '@emotion/css'
import { getStyles } from 'src/utils/utils/commonUtils'
import tableActionsBlockStyles from 'src/utils/components/CustomTable/styles/tableActionBlockStyles'
import { useTheme } from '@emotion/react'

function CustomPagination({
    showPagination,
    total,
    inputValue,
    onChange,
    pageSize,
    rowsPerPageOptions,
    page,
    onPageChange,
    onPageSizeChange,
    width,
    hideElementsOnTablets,
    hideUninformative,
    rows,
}) {
    const intl = useIntl()
    const theme = useTheme()
    const labelDisableRows = (props) => {
        const { from, to, count } = props
        
        return from + '-' + to + ' ' + intl.formatMessage({ id: 'из' }) + ' ' + count
    }
    
    const classes = getStyles(tableActionsBlockStyles, { inputValue, total, pageSize })

    return (
        showPagination &&
        !!total && (
            <Box sx={classes.pageNumberBlock}>
                {/* элементы пагинации скрыты если есть соотвествующий пропс и страниц меньше двух, а так же если меньше 50 записей*/}
                {hideUninformative && Math.ceil(total / pageSize) < 2 ? null : hideUninformative &&
                  rows?.length <= 50 ? null : (
                    <Box
                        sx={
                            hideElementsOnTablets
                                ? { ...classes.pageNumberContent, ...classes.hideOnTablets }
                                : { ...classes.pageNumberContent }
                        }
                    >
                        <Typography
                            sx={{
                                whiteSpace: 'nowrap',
                                fontSize: '0.875rem',
                                fontWeight: '500',
                                lineHeight: '1.125rem',
                                // [theme.breakpoints.down("sm")]: {
                                //     fontSize: "11px"
                                // }
                            }}
                        >
                            <FormattedMessage id={'Страница'} />
                        </Typography>
                        <TextField
                            value={inputValue}
                            onChange={onChange}
                            variant={'standard'}
                            sx={{
                                // стили для изменения размера инпута в зависимости от значения
                                '& .MuiInputBase-root': {
                                    width:
                                        inputValue > 999
                                            ? '42px'
                                            : inputValue > 99
                                            ? '32px'
                                            : inputValue > 9
                                            ? '21px'
                                            : '15px',
                                    height: '16px',
                                    margin: '0 8px',
                                    fontSize: '0.875rem',
                                    fontWeight: '500',
                                    // [theme.breakpoints.down('sm')]: {
                                    //     fontSize: '11px',
                                    // },
                                },
                            }}
                            inputProps={{
                                sx: { textAlign: 'center' },
                            }}
                            autoComplete='off'
                        />
                        <Typography
                            sx={{
                                whiteSpace: 'nowrap',
                                fontSize: '0.875rem',
                                fontWeight: '500',
                                lineHeight: '1.125rem',
                                // [theme.breakpoints.down('sm')]: {
                                //     fontSize: '11px',
                                // },
                            }}
                        >
                            <FormattedMessage id={'из'} /> {Math.ceil(total / pageSize)}
                        </Typography>
                    </Box>
                )}

                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions || [ 50, 100]}
                    sx={{
                        ...classes.tablePagination,
                        ...classes.hideOnTabletsPaginateElements,
                        //   если меньше 50 записей, то скрывать элементы пагинации
                        // "& .MuiTablePagination-selectLabel": {
                        //     display: hideUninformative && rows.length <= 50 ? "none" : "block",
                        //     background: "red"
                        // },

                        // "& .MuiInputBase-root": {
                        //     display: hideUninformative && rows.length <= 50 ? "none" : "flex",
                        //     background: "blue"
                        // }
                    }}
                    classes={{
                        root: css({
                            minWidth: 'fit-content',
                            overflowY: 'hidden !important',
                            color: 'black !important',
                        }),
                    }}
                    component='div'
                    tab={true}
                    count={total || 1}
                    rowsPerPage={pageSize}
                    page={page}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onPageSizeChange}
                    labelRowsPerPage={intl.formatMessage({ id: 'Размер' })}
                    labelDisplayedRows={labelDisableRows}
                    backIconButtonProps={{ title: intl.formatMessage({ id: 'Вернуться на предыдущую страницу' }) }}
                    nextIconButtonProps={{ title: intl.formatMessage({ id: 'Перейти к следующей странице' }) }}
                />
            </Box>
        )
    )
}

export default withWidth()(observer(CustomPagination))
