import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { FormattedMessage } from 'react-intl'
import { getStyles } from '../../utils/commonUtils'
import customTableStyles from './styles/customTableStyles'
import IconButton from '@mui/material/IconButton'
import tableSettings from '../../../assets/images/common/tableSettings.svg'
import CustomTableHead from 'src/utils/components/CustomTable/components/CustomTableHead/CustomTableHead'
import CustomTableBody from 'src/utils/components/CustomTable/components/CustomTableBody/CustomTableBody'
import TableActionsBlock from 'src/utils/components/CustomTable/components/TableActionsBlock/TableActionsBlock'
import TableScrollButtons from './components/TableScrollButtons/TableScrollButtons'
import { Button, Divider } from '@mui/material'
import { isDealer } from '../../../services/AccountService'
import AcceptBulkMobileBtn from './components/TableActionsBlock/AcceptBulkMobileBtn'

function CustomTable({
    columns,
    rows,
    page,
    pageSize,
    total,
    paginateCollection,
    onPageChange,
    onPageSizeChange,
    showPagination = true,
    onRowClick,
    containerStyle,
    doublePagination,
    summaryRow,
    rowsPerPageOptions,
    actionsBlock,
    additionalRowPropName,
    additionalRowCells,
    closeAdditionalInfo,
    handleSettings,
    isLoading,
    noContentText,
    scrollButtons,
    currentOpenedRowIndex,
    setCurrentOpenedRowIndex,
    checkedCount,
    acceptBulkAction,
    hideUninformative,
    showAcceptBulkMobileBtn,
    headCheackbox,
    frontendPagination,
    filterFromReports,
    redDelete,
    toggleIsRowOpen,
    ovVisible
}) {
    const [inputValue, setInputValue] = useState(page + 1)
    const [pageCollection, setPageCollection] = useState([])
    const [newRowsFront, setNewRowsFront] = useState([])

    if (!!handleSettings) {
        const haveSettings = columns.find((column) => column.title === 'settings')
        if (!haveSettings) {
            columns.push({
                title: 'settings',
                headerElement: (
                    <IconButton onClick={handleSettings}>
                        <img alt={''} src={tableSettings} />
                    </IconButton>
                ),
            })
        }
    }

    const classes = getStyles(customTableStyles, { containerStyle, showAcceptBulkMobileBtn })

    const setTableCollection = () => {
        if (paginateCollection) {
            const sliced = rows.slice(page * pageSize, pageSize * (page + 1))

            if (summaryRow && rows.length > 0) {
                sliced.push(summaryRow)
            }
            setPageCollection(sliced)
        }
    }

    useEffect(() => {
        if (paginateCollection) {
            onPageChange('', 0)
        }
    }, [total])

    useEffect(() => {
        if (paginateCollection) {
            onPageChange('', 0)
        }
    }, [pageSize])

    useEffect(() => {
        setTableCollection()
    }, [page, rows, pageSize])

    return (
        <Box sx={classes.root}>
            <Box>{filterFromReports}</Box>
            <TableActionsBlock
                rows={paginateCollection ? pageCollection : rows}
                actionsBlock={actionsBlock}
                showPagination={showPagination}
                total={total}
                setInputValue={setInputValue}
                pageSize={pageSize}
                inputValue={inputValue}
                page={page}
                rowsPerPageOptions={rowsPerPageOptions}
                onPageSizeChange={onPageSizeChange}
                onPageChange={onPageChange}
                doublePagination={doublePagination}
                additionalRowPropName={additionalRowPropName}
                hideUninformative={hideUninformative}
                setNewRowsFront={setNewRowsFront}
                frontendPagination={frontendPagination}
                newRowsFront={newRowsFront}
            >
                {/* // эта кнопка Принять заявки есть только у дилеров в мобильной версии */}
                {isDealer() && showAcceptBulkMobileBtn && (
                    <AcceptBulkMobileBtn acceptBulkAction={acceptBulkAction} checkedCount={checkedCount} />
                )}

                {!!filterFromReports ? null : <Divider sx={{ borderColor: '#E0DFDF' }} />}
                <Box id='custom-table-container' sx={{...classes.tableContainer, overflow: ovVisible ? 'visible':'auto'}}>
                    <Table sx={classes.table} aria-label='simple table'>
                        <CustomTableHead headCheackbox={headCheackbox} columns={columns} />

                        {isLoading ? null : (
                            <CustomTableBody
                            redDelete={redDelete}
                                rows={paginateCollection ? pageCollection : frontendPagination ? newRowsFront : rows}
                                columns={columns}
                                onRowClick={onRowClick}
                                closeAdditionalInfo={closeAdditionalInfo}
                                additionalRowCells={additionalRowCells}
                                additionalRowPropName={additionalRowPropName}
                                total={total}
                                setCurrentOpenedRowIndex={setCurrentOpenedRowIndex}
                                currentOpenedRowIndex={currentOpenedRowIndex}
                                toggleIsRowOpen={toggleIsRowOpen}
                            />
                        )}
                    </Table>
                    {scrollButtons && rows.length ? <TableScrollButtons rowsLength={rows.length} /> : null}
                </Box>

                {!total || isLoading ? (
                    <Box className={'jst-center'} width={1} p={4.5} sx={{ background: 'rgba(217, 217, 217, 0.20)' }}>
                        <Typography sx={{ fontWeight: '500', color: '#A9A9A9', fontSize: '13px' }}>
                            {noContentText ||
                                (isLoading ? (
                                    <FormattedMessage id={'загрузка..'} />
                                ) : (
                                    <FormattedMessage id={'нет данных для отображения'} />
                                ))}
                        </Typography>
                    </Box>
                ) : null}
            </TableActionsBlock>
        </Box>
    )
}

export default observer(CustomTable)
