import { isLooker } from 'src/services/AccountService'
import arrowIcon from '../../../../assets/images/common/selectArrow.svg'
import mobileArrow from '../../../../assets/images/common/mobileArrow.svg'
import mobileArrowDisabled from '../../../../assets/images/common/mobileArrowDisabled.svg'
import { checkMobileDevice } from '../../../utils/commonUtils'

const tableActionsBlockStyles = (theme, props) => {
    const { inputValue, total, pageSize } = props
    return {
        actionsContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '16px',
            // overflowX: 'scroll',
            overflowX: 'auto',

            [theme.breakpoints.down('sm')]: {
                padding: '14px 8px 8px 8px',
                marginTop: '4px',
            },
            '& .MuiTablePagination-root': {
                position: 'relative',
            },
        },
        pageNumberBlock: {
            overflowY: 'hidden',
            minWidth: 'fit-content',
            // overflowX: 'scroll',
            overflowX: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
        pageNumberContent: {
            height: '24px',
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
            marginRight: '32px',
            justifyContent: 'flex-end',
            paddingTop: '4px',
        },

        tablePagination: {
            '& .MuiTablePagination-actions': {
                marginLeft: '24px !important',
                overflow: 'inherit',

                '& .MuiIconButton-root': {
                    padding: '0',
                    margin: '0 8px',
                    // Стили с псевдоэлементами и проверками на мобильные девайсы нужны чтобы заменять нативные иконки MUI перехода по страницам.
                    '&:after': {
                        content: "''",
                        width: '24px',
                        height: '24px',
                    },

                    svg: {
                        display: 'none',
                    },
                    '&:first-child': {
                        backgroundImage: `url(${inputValue === 1 ? mobileArrowDisabled : mobileArrow})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                    },
                    '&:last-child': {
                        transform: 'rotate(180deg)',
                        backgroundImage: `url(${
                            inputValue === Math.ceil(total / pageSize) ? mobileArrowDisabled : mobileArrow
                        })`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                    },
                },
            },

            [theme.breakpoints.down('sm')]: {
                marginRight: '4px',
            },
            '& .MuiToolbar-root': {
                minHeight: '24px !important',
                height: '18px',
                padding: '0 !important',

                '.MuiTablePagination-displayedRows': {
                    marginTop: '16px',
                },
                '.MuiTablePagination-actions': {
                    display: 'flex',
                    justifyContent: 'space-between',
                },
            },
            '.MuiInputBase-root': {
                fontWeight: 500,
                paddingTop: '2px',
            },
        },
        hideOnTabletsPaginateElements: {
            '.MuiTablePagination-selectLabel, .MuiInputBase-root': {
                [theme.breakpoints.down('1400')]: {
                    display: 'none',
                },
            },
        },
        hideOnTablets: {
            [theme.breakpoints.down('1400')]: {
                display: 'none',
            },
        },
    }
}

export default tableActionsBlockStyles
