import moment from "moment/moment"
export const reportingPeriods = [
    {from: 1, to: 15},
    {from: 16, to: 31}
]
export function getPeriodDaysString(interval, month, year) {

    const firstDay = reportingPeriods[interval - 1].from
    const lastDay = interval === 2 ?
       ( year === 2023 && month === 12 ? "19" :
        moment(month,"MM").daysInMonth())
        : reportingPeriods[0].to
    return firstDay + "-" + lastDay
}