import React from "react"
import sliderBtnShevron from "../../assets/images/slider/sliderScrollIcon.svg"
const CarouselCustomBtn = () => {
    return (
        <img
            style={{
                borderRadius: "0 48px 48px 0",
                height: "96px",
                width: "48px",
                background: "rgba(0,0,0,0.15)",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                "-webkit-tap-highlight-color": "rgba(0, 0, 0, 0)",
                "&:hover": {
                    background: "rgba(0,0,0,0.3)"
                },
                zIndex: 100
            }}
            alt="control button"
            src={sliderBtnShevron}
        />
    )
}

export default CarouselCustomBtn
