import fxStyles from "../../../utils/styles/fxStyles"

const mobileRequestsManagementStyles = (theme) => ({
    searchBlock: {
        [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: "1fr"
        }
    },
    createRequestDesctopBtn: {
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    lupeIconWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative"
    },
    haveFiltersDot: {
        display: "none",
        width: "6px",
        height: "6px",
        borderRadius: "50%",
        backgroundColor: "#44A2FF",
        position: "absolute",
        right: "4px",
        top: "2px",
        [theme.breakpoints.down("sm")]: {
            display: "block"
        }
    },
    createRequestMobileBtn: {
        display: "none",
        transition: "all 0.5s",
        [theme.breakpoints.down("sm")]: {
            display: "block",
            position: "fixed",
            bottom: "77px",
            right: 0,
            zIndex: 2
        }
    }
})

export default mobileRequestsManagementStyles
