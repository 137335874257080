import React from 'react'
import { PDFViewer } from '@react-pdf/renderer'
import PTRootUIBold from '../../../../assets/fonts/Arimo/Arimo-Bold.ttf'
import PTRootUI from '../../../../assets/fonts/Arimo/Arimo-Regular.ttf'
import { Font, Document, Page, StyleSheet, View, Text } from '@react-pdf/renderer'
import { getComp } from '../../../../utils/utils/DI'
import { getTitleFinancialSource } from '../../../../helpers/switchFinancialSource'
import moment from 'moment'
import { formatAddress } from '../../../../helpers/ordersUtiles'

Font.register({
    family: 'PT Root UI',
    fonts: [{ src: PTRootUI }, { src: PTRootUIBold, fontWeight: 700 }],
})



const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4',
        paddingVertical: 30,
    },
    section: {
        // margin: 10,
        paddingLeft: 30,
        paddingRight: 30,
        flexGrow: 1,
    },
    table: {
        flexDirection: 'column',
        marginTop: 10,
        borderWidth: 1,
        borderColor: '#000',
    },
    tableRow: {
        flexDirection: 'row',
    },
    tableCol: {
        flex: 1,
        borderRightWidth: 1,
        borderBottomWidth: 1,
        borderColor: '#000',
        padding: 5,
        fontFamily: 'PT Root UI',
        fontSize: '9px',
    },
    tableHeaderCol: {
        fontWeight: 'bold',
    },
    tableHeader: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#fff',
    },
    tableHeaderText: {
        alignItems: 'center',
        textAlign: 'center',
        color: '#fff',
        padding: 5,
    },
    tableBody: {
        flexDirection: 'row',
    },
    tableColNumber: {
        flex: 0.5, // Уменьшаем гибкость (flex) для номера
        borderRightWidth: 1,
        borderBottomWidth: 1,
        borderColor: '#000',
        padding: 5,
        fontFamily: 'PT Root UI',
        fontSize: 9, // Убираем кавычки, так как это число, а не строка
    },

    tableColAddress: {
        flex: 2, // Увеличиваем гибкость для адреса
        borderRightWidth: 1,
        borderBottomWidth: 1,
        borderColor: '#000',
        padding: 5,
        fontFamily: 'PT Root UI',
        fontSize: 9, // Убираем кавычки, так как это число, а не строка
    },
})

const DealerRequestPDF = (data) => {
    // const statusStore = getComp('StatusesStore')
    // const {getServiceOrderStatusName, getStatusName} = statusStore
    const {  getInstallationReasonName } = getComp('InstallationReasonStore')
    return (
        <Document>
            <Page size='A4' orientation='landscape' style={styles.page} wrap>
                <View style={styles.section}>
                    <View style={styles.table}>
                        <View style={[styles.tableRow, styles.tableHeader]}>
                            <Text style={[styles.tableColNumber, styles.tableHeaderCol]}>№</Text>
                            <Text style={[styles.tableCol, styles.tableHeaderCol]}>Причина установки</Text>
                            <Text style={[styles.tableCol, styles.tableHeaderCol]}>Оплата</Text>
                            <Text style={[styles.tableCol, styles.tableHeaderCol]}>Номер заявки</Text>
                            <Text style={[styles.tableCol, styles.tableHeaderCol]}>Дата создания</Text>
                            <Text style={[styles.tableCol, styles.tableHeaderCol]}>Дата установки</Text>
                            <Text style={[styles.tableCol, styles.tableHeaderCol]}>ФИО</Text>
                            <Text style={[styles.tableColAddress, styles.tableHeaderCol]}>Адрес</Text>
                            <Text style={[styles.tableCol, styles.tableHeaderCol]}>Номер телефона</Text>
                            {/* <Text style={[styles.tableCol, styles.tableHeaderCol]}>Льготная категория</Text> */}
                        </View>
                        {data.data.map((item, index) => (
                            <View style={styles.tableRow} key={index} wrap={false}>
                                <Text style={styles.tableColNumber}>{index + 1}</Text>
                                <Text style={styles.tableCol}>{ getInstallationReasonName(item.reasonId)}</Text>
                                <Text style={styles.tableCol}>{ item.financialSourceId ===4? null: getTitleFinancialSource(item.financialSourceId)}</Text>
                                <Text style={styles.tableCol}>{item.id}</Text>
                                <Text style={styles.tableCol}>{item.created ? moment(item.created).format('DD.MM.YYYY') : null}</Text>
                                <Text style={styles.tableCol}>{item.installationDate ? moment(item.installationDate).format('DD.MM.YYYY') : null}</Text>
                                <Text style={styles.tableCol}>{`${item.surname ?? ''} ${item.name ?? ''} ${item.patronymic ?? ''} `}</Text>
                                <Text style={styles.tableColAddress}>{formatAddress(item)}</Text>
                                <Text style={styles.tableCol}>{item.phone}</Text>
                                {/* <Text style={styles.tableCol}>{item.preferentialCategory}</Text> */}
                            </View>
                        ))}
                    </View>
                </View>
            </Page>
        </Document>
    )
}

export default DealerRequestPDF

// export const TestView = (data) => (
//     <div style={{ width: '96vw', height: '85vh' }}>
//         <PDFViewer style={{ width: '100%', height: '100%' }}>
//             <DealerRequestPDF data={data} />
//         </PDFViewer>
//     </div>
// )
