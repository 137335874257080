const tableHeadCellStyles = (theme, props) => {
    const headerTextStyles = props.headerTextStyles || {}
    return {
        headerCell: {
            padding: '4px',
            background: '#fff',
            color: '#A9A9A9',
            height: '54px',
            cursor: props.handleClick ? 'pointer' : 'default',
            minWidth: props.minWidth || '60px',
            width: props.cellWidth || '60px',
            img: props.handleClick
                ? {
                      filter: 'invert(60%)',
                  }
                : null,
            '&:hover': props.handleClick
                ? {
                      backgroundColor: props.handleClick ? 'rgb(175,211,239)' : null,
                      color: props.handleClick ? 'rgb(255, 255, 255)' : '#A9A9A9',
                      img: {
                          filter: props.handleClick ? 'invert(100%)' : 'invert(60%)',
                      },
                  }
                : null,
        },
        headerText: {
            fontSize: '13px',
            fontWeight: navigator.userAgent.includes('Firefox') ? '400' : '500',
            textAlign: props.headerStart ? 'start' : 'center',
            ...headerTextStyles,
        },
        contentContainer: {
            justifyContent: props.headerStart ? 'flex-start' : 'center',
            padding: props.padding ? props.padding : null,
        },
    }
}

export default tableHeadCellStyles
