const tableScrollButtonsStyles = (theme) => ({
    container: {
        transition: "visibility .3s linear",
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    leftBtn: {
        borderRadius: "0 48px 48px 0"
    },
    rightBtn: {
        borderRadius: "0 48px 48px 0"
    },
    btn: {
        zIndex: "10",
        top: "50%",
        "&:hover": {
            background: "rgba(0,0,0,0.2)"
        }
    },
    rightBtnPosition: {
        right: "72px",
        [theme.breakpoints.down("lg")]: {
            right: "32px"
        },
        [theme.breakpoints.down("1024")]: {
            right: "16px"
        },
        [theme.breakpoints.down("744")]: {
            right: "8px"
        }
    },
    leftBtnPosition: {
        left: "72px",
        [theme.breakpoints.down("lg")]: {
            left: "32px"
        },
        [theme.breakpoints.down("1024")]: {
            left: "16px"
        },
        [theme.breakpoints.down("744")]: {
            left: "8px"
        }
    }
})

export default tableScrollButtonsStyles
