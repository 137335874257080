import React, { useState } from 'react'
import { observer } from 'mobx-react'
import Box from '@mui/material/Box'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import { Tooltip } from '@mui/material'
import dawDown from 'src/assets/images/common/daw-down-black.svg'
import dawRight from 'src/assets/images/common/daw-right-black.svg'
import { getStyles } from 'src/utils/utils/commonUtils'
import tableBodyCellStyles from 'src/utils/components/CustomTable/styles/tableBodyCellStyles'
import { isDealer } from '../../../../../services/AccountService'
import withWidth, { isWidthDown } from '@mui/material/Hidden/withWidth'

function TableBodyCell({ column, isRowOpen, error, row, rowIndex, width, bodyCellWidth }) {
    const { totalRow } = row
    const { renderCellValue, propName, renderCell, customTextStyles } = column
    const openRowIcon = isRowOpen ? dawDown : dawRight
    const classes = getStyles(tableBodyCellStyles, { error, row, column })
    const [isTooltipOpen, setTooltipOpen] = useState(false)
    const toggleTooltip = () => {
        error && setTooltipOpen((prevState) => !prevState)
    }
    const tooltipTitle = isTooltipOpen ? <Box sx={classes.error}>{error.message}</Box> : null

    return (
        <Tooltip placement='top' title={tooltipTitle}>
            <TableCell onMouseEnter={toggleTooltip} onMouseLeave={toggleTooltip} sx={classes.cell} align={'left'}>
                <Box
                    sx={
                        isDealer() && isWidthDown('sm', width) && column.propName === 'details'
                            ? {
                                  ...classes.miniLastRowCell,
                              }
                            : {
                                  ...classes.cellContainer,
                                  height: isRowOpen ? 'auto' : undefined,
                              }
                    }
                >
                    {renderCellValue ? (
                        <Typography variant={'body1'} sx={{ ...classes.contentText, ...customTextStyles }}>
                            {renderCellValue(row) || '-'}
                        </Typography>
                    ) : propName === 'openRow' && !totalRow ? (
                        <img alt={''} src={openRowIcon} />
                    ) : renderCell ? (
                        renderCell(row)
                    ) : (
                        ''
                    )}
                </Box>
            </TableCell>
        </Tooltip>
    )
}

export default withWidth()(observer(TableBodyCell))
