import React from 'react'
import { observer } from 'mobx-react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import cross from '../../assets/images/common/cross_gray.svg'
import { getStyles } from '../../utils/utils/commonUtils'
import chipsStyles from './styles/chipsStyles'

function Chip({ action, value }) {
    const classes = getStyles(chipsStyles)
    return (
        <Box sx={classes.chipContainer}>
            {typeof value === 'function' ? (
                value()
            ) : (
                <Typography variant={'body1'} sx={classes.chipText}>
                    {value}{' '}
                </Typography>
            )}
            {action ? (
                <IconButton onClick={action} sx={classes.iconButton}>
                    <img alt={''} src={cross} />
                </IconButton>
            ) : null}
        </Box>
    )
}

export default observer(Chip)
