import React from 'react'
import fullFree from '../assets/images/financialSource/fullFree.svg'
import fullSubsidy from '../assets/images/financialSource/fullSubsidy.svg'
import partialSubsidy from '../assets/images/financialSource/partialSubsidy.svg'
export const getIconFinancialSource = (financialSourceId) => {
    switch (financialSourceId) {
        case 1:
            return fullSubsidy
        case 2:
            return partialSubsidy
        case 3:
            return fullFree

        default:
            return null
    }
}

export const getTitleFinancialSource = (financialSourceId) => {
    switch (financialSourceId) {
        case 1:
            return 'Полная дотация'
        case 2:
            return 'Частичная дотация'
        case 3:
            return 'За счет абонента'

        default:
            return null
    }
}
