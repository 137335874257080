import { observer } from 'mobx-react'
import TableRow from '@mui/material/TableRow'
import React, { useEffect, useState } from 'react'
import { getStyles } from 'src/utils/utils/commonUtils'
import tableBodyRowStyles from 'src/utils/components/CustomTable/styles/tableBodyRowStyles'
import TableBodyCell from 'src/utils/components/CustomTable/components/CustomTableBody/TableBodyCell'

function TableBodyRow({
    row,
    closeAdditionalInfo,
    onRowClick,
    columns,
    rowIndex,
    additionalRowCells,
    additionalRowPropName,
    total,
    setCurrentOpenedRowIndex,
    currentOpenedRowIndex,
    redDelete,
    toggleIsRowOpen
}) {
    const classes = getStyles(tableBodyRowStyles, {
        onRowClick,
        closeAdditionalInfo,
        row,
        rowIndex,
        additionalRowPropName,
        redDelete,
    })
    const [isRowOpen, setIsRowOpen] = useState(false)

    const toggleRow = () => {
        toggleIsRowOpen(row.authorId)
    }
    const handleRowClick = (event) => {
        event.stopPropagation()
        closeAdditionalInfo ? toggleRow() : null

        // если функция изменения индекса передана в компоненте, то вызываем ее
        // в CustomTableBody через useEffect вызовится функция открытия дровера через использование индекса
        if (setCurrentOpenedRowIndex) {
            setCurrentOpenedRowIndex(rowIndex)
            onRowClick && onRowClick({ data: row, rowIndex: rowIndex })
            return
        }
        // иначе вызываем функцию onRowClick - открытие дровера

        onRowClick && onRowClick({ data: row, rowIndex: rowIndex })
    }

    return (
        <>
            <TableRow
                onClick={handleRowClick}
                sx={{
                    ...classes.row,
                    '&.Mui-selected': {
                        ...classes.row,
                        outline: 'rgba(93, 175, 239, 0.20) solid 4px',
                        outlineOffset: '-4px',
                    },
                }}
                // когда индекс активной строки таблицы совпадает с текущим - выделяем строку
                selected={currentOpenedRowIndex === rowIndex}
            >
                {columns.map((column, columnIndex) => {
                    const error = row.errors ? row.errors.find((el) => columnIndex === el.columnId) : null
                    return (
                        <TableBodyCell
                            key={columnIndex}
                            isRowOpen={isRowOpen}
                            error={error}
                            row={row}
                            column={column}
                            rowIndex={rowIndex}
                        />
                    )
                })}
            </TableRow>
            {row.totalRow && !total ? null : (
                <>
                    {additionalRowPropName
                        ? row.isRowOpen &&
                          row[additionalRowPropName] &&
                          row[additionalRowPropName].map((additionalRow, i) => {
                              return (
                                  <TableRow key={'additionalRow' + i}>
                                      {additionalRowCells.map((el, i) => {
                                          return (
                                              <TableBodyCell
                                                  key={'additionalRowCell' + i}
                                                  row={additionalRow}
                                                  column={el}
                                              />
                                          )
                                      })}
                                  </TableRow>
                              )
                          })
                        : null}
                </>
            )}
        </>
    )
}

export default observer(TableBodyRow)
