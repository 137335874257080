import {Box, Button, Typography} from "@mui/material"
import React from "react"
import {FormattedMessage} from "react-intl"
import {getStyles} from "../../../../utils/commonUtils"
import customTableStyles from "../../styles/customTableStyles"
import {getDeclensionOfTheWord} from "src/helpers/declensionOfTheWord"

const AcceptBulkMobileBtn = ({acceptBulkAction, checkedCount}) => {
    const classes = getStyles(customTableStyles)

    return (
        <Box sx={classes.acceptRequestsBtnWrapper}>
            <Button
                fullWidth
                variant="contained"
                disabled={!checkedCount}
                onClick={acceptBulkAction}
                sx={{
                    "&:hover": {background: !checkedCount ? "rgba(0, 0, 0, 0.12)" : "rgb(22,104,185)"}
                }}
            >
                {!checkedCount ? (
                    <FormattedMessage id="Принять заявки" />
                ) : (
                    <Typography sx={{fontWeight: "500", fontSize: "15px"}}>
                        <FormattedMessage id="Принять" /> {checkedCount}{" "}
                        {getDeclensionOfTheWord(["заявку","заявки","заявок","заявку","заявки","заявок"], checkedCount)}
                    </Typography>
                )}
            </Button>
        </Box>
    )
}

export default AcceptBulkMobileBtn
