import React from 'react'
import { observer } from 'mobx-react'
import Box from '@mui/material/Box'

function Chips({ filters, setChip }) {
    return (
        <Box
            sx={{
                width: 'fit-content',
                display: 'flex',
                flexWrap: 'nowrap',
                cursor: 'default',
            }}
            onClick={(e) => e.stopPropagation()}
        >
            {setChip &&
                filters.map((el) => {
                    return <React.Fragment key={el.propName + el.value}>{setChip(el)}</React.Fragment>
                })}
        </Box>
    )
}

export default observer(Chips)
