import {useEffect, useState} from "react"
import {checkMobileDevice} from "../utils/commonUtils"
// эвены скролла на десктопе пока отключены. используется функционал только для мобильных устройств
// при необходимости получения направления прокрутки колесика мыши - раскомментировать
function useScrollDirectionDown() {
    const [downDirection, setDownDirection] = useState(null)

    useEffect(() => {
        let touchStartY = null
        let touchMoveY = null
        // function handleScroll() {
        //     window.onwheel = (e) => {
        //         if (e.deltaY >= 0) {
        //             setDownDirection(false)
        //         } else {
        //             setDownDirection(true)
        //         }
        //     }
        // }

        function handleTouchStart(e) {
            touchStartY = e.touches[0].clientY
        }

        function handleTouchMove(e) {
            touchMoveY = e.touches[0].clientY
            if (touchStartY < touchMoveY) {
                setDownDirection(false)
            } else {
                setDownDirection(true)
            }
        }

        if (checkMobileDevice()) {
            window.addEventListener("touchstart", handleTouchStart)
            window.addEventListener("touchmove", handleTouchMove)
        } else {
            // window.addEventListener("scroll", handleScroll)
        }

        return () => {
            // window.removeEventListener("scroll", handleScroll)
            window.removeEventListener("touchstart", handleTouchStart)
            window.removeEventListener("touchmove", handleTouchMove)
        }
    }, [])

    return downDirection
}

export default useScrollDirectionDown
