import {Box} from "@mui/material"
import React, {useEffect, useState} from "react"
import CarouselCustomBtn from "../../../../../components/PhotoGalleryDialog/CarouselCustomBtn"
import tableScrollButtonsStyles from "./styles/tableScrollButtonsStyles"
import {getStyles} from "../../../../utils/commonUtils"
import {observer} from "mobx-react"

const TableScrollButtons = ({rowsLength}) => {
    const classes = getStyles(tableScrollButtonsStyles)
    // направление скролла'left' или 'right'
    const [scrollDirection, setScrollDirection] = useState({direction: null})
    const [containerVisible, setContainerVisible] = useState("hidden")
    const [containerOpacity, setContainerOpacity] = useState(0)
    const [currentButtonPosition, setCurrentButtonPosition] = useState("fixed")
    const [windowSize, setWindowSize] = useState(window.innerWidth)

    // переменная для хранения и очистки setInterval
    let scrollInterval = null
    const setVisibleButtons = () => {
        setContainerOpacity(1)
        setContainerVisible("visible")
    }

    let timeout = null
    useEffect(() => {
        timeout = setTimeout(setVisibleButtons, 600)
        return () => {
            clearTimeout(timeout)
        }
    }, [rowsLength])

    useEffect(() => {
        const resizeListener = () => {
            setWindowSize(window.innerWidth)
        }
        window.addEventListener("resize", resizeListener)

        return () => {
            window.removeEventListener("resize", resizeListener)
        }
    }, [])

    const changePosition = () => {
        if (window.innerHeight < document.body.clientHeight) {
            setCurrentButtonPosition("fixed")
        } else {
            setCurrentButtonPosition("absolute")
        }
    }

    useEffect(() => {
        if (containerVisible === "visible") {
            changePosition()
        }
        setTimeout(() => {
            changePosition()
        }, 600)
    }, [rowsLength])

    useEffect(() => {
        let tableContainer = document.getElementById("custom-table-container")

        let leftBtn = document.getElementById("table-scroll-left-btn")
        let rightBtn = document.getElementById("table-scroll-right-btn")

        leftBtn.style.opacity = "0"
        leftBtn.style.display = "none"
        if (tableContainer.clientWidth >= tableContainer.scrollWidth) {
            rightBtn.style.opacity = "0"
        } else {
            rightBtn.style.opacity = "1"
            rightBtn.style.pointerEvents = "auto"
        }

        const onScrollFunction = (e) => {
            if (e.target.scrollLeft <= 0) {
                leftBtn.style.opacity = "0"
                leftBtn.style.pointerEvents = "none"
                leftBtn.style.display = "none"
            } else {
                leftBtn.style.opacity = "1"
                leftBtn.style.pointerEvents = "auto"
                leftBtn.style.display = "block"
            }
            // -10 чтобы правая кнопка исчезала чуть раньше
            if (e.target.scrollLeft >= e.target.scrollWidth - e.target.clientWidth - 10) {
                rightBtn.style.opacity = "0"
                rightBtn.style.pointerEvents = "none"
            } else {
                rightBtn.style.opacity = "1"
                rightBtn.style.pointerEvents = "auto"
            }
        }

        tableContainer.addEventListener("scroll", onScrollFunction)

        return () => {
            tableContainer.removeEventListener("scroll", onScrollFunction)
        }
    }, [windowSize])

    useEffect(() => {
        if (scrollDirection.direction === "left") {
            scrollInterval = setInterval(() => {
                document.getElementById("custom-table-container").scrollLeft -= 2
            }, 10)
        } else if (scrollDirection.direction === "right") {
            scrollInterval = setInterval(() => {
                document.getElementById("custom-table-container").scrollLeft += 2
            }, 10)
        }
    }, [scrollDirection])

    const moveLeft = () => {
        setScrollDirection((prevState) => {
            return {...prevState, direction: "left"}
        })
    }

    const moveRight = () => {
        setScrollDirection((prevState) => {
            return {...prevState, direction: "right"}
        })
    }

    const mouseOver = () => {
        setScrollDirection((prevState) => {
            return {...prevState, direction: null}
        })
        clearInterval(scrollInterval)
    }

    return (
        <Box
            sx={{
                ...classes.container,
                opacity: containerOpacity,
                visibility: containerVisible
            }}
        >
            <Box
                onMouseOver={moveLeft}
                onMouseOut={mouseOver}
                id="table-scroll-left-btn"
                sx={{
                    ...classes.leftBtn,
                    ...classes.btn,
                    position: currentButtonPosition,
                    left: currentButtonPosition === "absolute" ? "0" : classes.leftBtnPosition,
                    transform: currentButtonPosition === "absolute" ? "translateY(-50%)" : null
                }}
            >
                <CarouselCustomBtn />
            </Box>
            <Box
                onMouseOver={moveRight}
                onMouseOut={mouseOver}
                id="table-scroll-right-btn"
                sx={{
                    ...classes.rightBtn,
                    ...classes.btn,
                    position: currentButtonPosition,
                    right: currentButtonPosition === "absolute" ? "0" : classes.rightBtnPosition,
                    transform:
                        currentButtonPosition === "absolute" ? "translateY(-50%) rotate(180deg)" : "rotate(180deg)"
                }}
            >
                <CarouselCustomBtn />
            </Box>
        </Box>
    )
}

export default observer(TableScrollButtons)
