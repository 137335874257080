
import { ButtonBase, Tooltip, useTheme } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import OrderValue from '../../views/RequestsManagement/components/NewRequestFilters/components/OrderValue'
import IconButton from '@mui/material/IconButton'
import lupe from '../../assets/images/common/lupe.svg'
import FiltersForm from '../FiltersForm/FiltersForm'
import { observer } from 'mobx-react'
import Chips from '../Chips/Chips'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { isLooker } from 'src/services/AccountService'
import withWidth, { isWidthDown, isWidthUp } from '@mui/material/Hidden/withWidth'
import selectArrow from '../../assets/images/common/selectArrow.svg'
import commonStyles from '../../styles/commonStyles'
import { getStyles } from '../../utils/utils/commonUtils'
import mobileRequestsManagementStyles from '../../views/RequestsManagement/styles/mobileRequestManagmentStyles'
import { isDealer } from '../../services/AccountService'
import roundedPlus from '../../assets/images/common/roundedPlus.svg'
import useScrollDirectionDown from '../../utils/hooks/useScrollDirectionDown'
import downLoad from '../../assets/images/common/downloadWhite.svg'
import downLoadGray from '../../assets/images/common/downloadGray.svg'
import { gState } from '../../utils/controllers/GlobalStateController'
import DealerRecuestPDF from '../../views/RequestsManagement/components/DealerRecuestPDF/DealerRecuestPDF'
import { pdf } from '@react-pdf/renderer'
import { useLocation } from 'react-router'

function NewFilters({
    totalCollectionLength,
    filtersStore,
    filtersData,
    onSearch,
    onClear,
    setChip,
    filtersContainerHeight,
    title,
    actionButtonProps,
    width,
    filterRowStyles = {},
    scrollIsAlreadyLocked,
    downloadRequestsListPDF,
    selectOrderCollection,
    gray,
    selectOrdersStore
}) {
    const theme = useTheme()
    const intl = gState['intl']
    const btnRef = useRef(null)
    const [isFiltersOpen, setIsFiltersOpen] = useState(false)
    const classes = { ...getStyles(commonStyles), ...getStyles(mobileRequestsManagementStyles) }
    const location = useLocation()
    const currentPath = location.pathname
    const handleClear = () => {
        if (onClear) {
            onClear()
        } else {
            filtersStore.clear()
            onSearch()
        }
    }
    // показывает и скрывает кнопку создания заявки на мобильных в зависимости от направления скролла
    const directionDown = useScrollDirectionDown()

    useEffect(() => {
        if (btnRef.current) {
            if (directionDown) {
                btnRef.current.style = 'bottom: -100px'
            } else {
                btnRef.current.style = 'bottom: 77px'
            }
        }
    }, [directionDown])



useEffect(() => {
    if (isFiltersOpen) {
        // Отключаем скролл
        document.body.style.overflow = 'hidden';
    } else {
        // Восстанавливаем скролл
        document.body.style.overflow = '';
    }

    // Очищаем эффект при размонтировании компонента
    return () => {
        document.body.style.overflow = '';
    };
}, [isFiltersOpen]);

    return (
        <Box
            sx={{
                ...((actionButtonProps && !isLooker()) ||
                (actionButtonProps && !isLooker() && actionButtonProps?.name !== 'Создать заявку')
                    ? {
                          display: 'grid',
                          gap: '20px',
                          gridTemplateColumns: '1fr 281px',
                      }
                    : {}),
                ...(isDealer()
                    ? {
                          ...classes.searchBlock,
                      }
                    : {
                          [theme.breakpoints.down('sm', width)]: {
                              marginTop: '16px',
                              gridTemplateColumns: '1fr 60px',
                              gap: '12px',
                          },
                      }),
            }}
        >
            <ButtonBase
                id={'searchBox'}
                sx={{
                    ...classes.searchBox,
                    ...filterRowStyles,
                    background: gray ? (isFiltersOpen ? null : 'rgba(224, 223, 223, 1)') : null,
                }}
                disableTouchRipple={true}
            >
                <OrderValue value={totalCollectionLength} active={isFiltersOpen} title={title} />
                {isDealer() && isWidthDown('sm', width) ? (
                    <Box></Box>
                ) : (
                    <Box
                        sx={{
                            // overflowX: 'scroll',
                            overflowY: 'auto',
                            padding: '0 16px',
                            height: '100%',
                            cursor: 'pointer',
                        }}
                        onClick={() => setIsFiltersOpen((prevState) => !prevState)}
                    >
                        {setChip ? (
                            <Chips setChip={(params) => setChip(params, true)} filters={filtersStore.selectedFilters} />
                        ) : null}
                    </Box>
                )}

                <IconButton onClick={() => setIsFiltersOpen((prevState) => !prevState)} sx={classes.filterOpenBtn}>
                    {isFiltersOpen ? (
                        <img
                            alt={'arrow up'}
                            src={selectArrow}
                            style={{ transform: 'rotate(180deg)', transition: '0.5s all' }}
                        />
                    ) : (
                        <Box sx={classes.lupeIconWrapper}>
                            {isDealer() && !!filtersStore.selectedFilters.length && (
                                <Box sx={classes.haveFiltersDot}></Box>
                            )}
                            <img alt={'lupe'} src={lupe} />
                        </Box>
                    )}
                </IconButton>

                <FiltersForm
                    opened={isFiltersOpen}
                    filtersStore={filtersStore}
                    onClear={handleClear}
                    onSearch={onSearch}
                    onClose={() => setIsFiltersOpen(false)}
                    filtersData={filtersData}
                    setChip={setChip}
                    filtersContainerHeight={filtersContainerHeight}
                    scrollIsAlreadyLocked={scrollIsAlreadyLocked}
                    width={width}
                />
            </ButtonBase>

            {(actionButtonProps && !isLooker() && !isDealer()) ||
            (actionButtonProps && !isLooker() && actionButtonProps?.name !== 'Создать заявку') ? (
                <Tooltip title={actionButtonProps?.tooltip} placement={'top-start'}>
                    <Box>
                <Button
                    variant={'contained'}
                    id={'createRequestButton'}
                    color={'primary'}
                    onClick={actionButtonProps?.onClick}
                    sx={
                        isDealer()
                            ? {
                                  ...classes.buttonActive,
                                  borderRadius: '4px',
                                  width: '100%',
                                  maxWidth: '281px',
                                  background:  gray && selectOrdersStore?.selectedOrders?.size  ? 'rgba(224, 23, 23, 0.6)': null,
                                  '&:hover': {
                                    background:  gray && selectOrdersStore?.selectedOrders?.size  ? 'rgba(224, 23, 23, 0.5)': null,
                                },
                                  gridColumn: 2,
                                  ...classes.createRequestDesctopBtn,
                              }
                            : {
                                  ...classes.buttonActive,
                                  borderRadius: '4px',
                                  width: '100%',
                                  maxWidth: '281px',
                                  gridColumn: 2,
                                  [theme.breakpoints.down('sm', width)]: {
                                      paddingX: '8px',
                                      minWidth: '60px',
                                  },
                              }
                    }
                    disabled={actionButtonProps?.disabled}
                    startIcon={<img alt={''} src={actionButtonProps?.icon} />}
                >
                    {isWidthUp('sm', width) && (
                        <Box pl={1.5}>
                            <Typography variant={'h6'} sx={{ textTransform: 'uppercase', whiteSpace: 'nowrap' }}>
                                {actionButtonProps?.name}
                            </Typography>
                        </Box>
                    )}
                </Button>
                </Box>
                </Tooltip>
            ) : null}

            {isDealer() && currentPath === '/requests' && actionButtonProps?.name !== 'Детали отчета' && (
                <Button
                    variant='contained'
                    id={'newFiltersButton1'}
                    color='primary'
                    onClick={downloadRequestsListPDF}
                    disabled={selectOrderCollection && [...selectOrderCollection].length === 0}
                    sx={{
                        borderRadius: '4px',
                        width: '100%',
                        maxWidth: '281px',
                        gridColumn: 2,
                    }}
                    startIcon={
                        <img
                            src={
                                selectOrderCollection && [...selectOrderCollection].length !== 0
                                    ? downLoad
                                    : downLoadGray
                            }
                            alt='Download Icon'
                        />
                    }
                >
                    <Box pl={1.5}>
                        <Typography variant={'h6'} sx={{ textTransform: 'uppercase', whiteSpace: 'nowrap' }}>
                            {'Сохранить в файл'}
                        </Typography>
                    </Box>
                </Button>
            )}

            {actionButtonProps && !isLooker() && isDealer() ? (
                <Button
                    ref={btnRef}
                    id='createRequestBtn'
                    onClick={actionButtonProps.onClick}
                    disabled={actionButtonProps.disabled}
                    startIcon={<img alt={'add request'} src={roundedPlus} />}
                    sx={{ ...classes.buttonActive, ...classes.createRequestMobileBtn }}
                />
            ) : null}
            {isFiltersOpen ? (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        background: 'rgba(0,0,0,0.4)',
                        zIndex: 1000,
                    }}
                    onClick={() => setIsFiltersOpen(false)}
                />
            ) : null}
        </Box>
    )
}

export default withWidth()(observer(NewFilters))
