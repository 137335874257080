const tableBodyCellStyles = (theme, { error, row, column, isRowOpen }) => {
    const { cellWidth, cellMinWidth, totalRow } = row

    return {
        error: {
            padding: '8px',
            borderRadius: '8px',
            border: '1px solid red',
            background: '#fff',
            width: '150px',
        },
        cell: {
            boxSizing: 'border-box',
            minWidth: column.cellMinWidth || '60px',
            width: column.cellWidth,
            borderTop: (error ? '2px solid rgba(224, 23, 23, 0.6)' : '1px solid #E0DFDF') + ' !important',
            backgroundColor: error ? 'rgba(224, 23, 23, 0.1)' : totalRow ? 'rgba(93, 175, 239, 0.20)' : null,
            cursor: error ? 'pointer' : null,
            padding: column.paddingBodyCell || 1,
            position: 'relative',
            '&.MuiTableCell-root': {
                '.MuiBox-root': {
                    margin: '0',
                    // width: "fit-content"
                },
                [theme.breakpoints.down('sm')]: {
                    border: 'none !important',
                },
            },
            [theme.breakpoints.down('sm')]: {
                padding: '4px 0 4px 0',
            },
        },
        cellContainer: {
            margin: '0 auto',
            height: `calc(56px - 16px)`,
            display: 'flex',
            justifyContent: column.justifyContent || 'center',
            alignItems: 'center',
        },
        contentText: {
            textAlign: column.textAlign || 'center',
            padding: column.padding || null,
            display: '-webkit-box',
            WebkitLineClamp: '2',
            // '-webkit-box-orient': 'vertical',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            fontSize: '14px',
            fontWeight: navigator.userAgent.includes('Firefox') ? '400' : '500',
            ...(totalRow ? { fontWeight: 600, fontSize: '20px' } : {}),
        },

        miniLastRowCell: {
            width: '100% !important',
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '10px',
        },
    }
}

export default tableBodyCellStyles
