
export const getDeclensionOfTheWord = (words, count) => {
    if(words){
       return  count === 1
            ? words[0] || ""
            : count < 5
                ? words[1] || ""
                : count < 21 || count % 10 === 0
                    ? words[2] || ""
                    : count % 10 === 1
                        ? words[3] || ""
                        : count % 10 < 5
                            ? words[4] || ""
                            : words[5] || ""
    } else {
        return ""
    }
}