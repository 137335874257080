const customTableStyles = (theme, props) => {
    const containerStyle = props?.containerStyle || {}
    const bottomPaddingForBtn = props?.showAcceptBulkMobileBtn || null
    return {
        tableContainer: {
            // overflow: 'scroll',
            overflow: 'auto',
            ...containerStyle,
            [theme.breakpoints.down('sm')]: {
                // overflow: "hidden"
                overflow: 'auto',
            },
        },
        root: {
            background: '#FFF',
            borderRadius: '8px',
            boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.15)',
            padding: '0 8px 24px',
            [theme.breakpoints.down('sm')]: {
                padding: '0',
                marginBottom: bottomPaddingForBtn ? '100px' : '56px',
            },
        },
        table: {
            width: '100%',
            boxShadow: 'none',
        },
        tableRow: {
            position: 'sticky',
            top: '0px',
            zIndex: 10,
        },
        acceptRequestsBtnWrapper: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
                position: 'fixed',
                bottom: '0',
                left: '0',
                right: '0',
                padding: '24px 6px 24px 6px',
                width: '100%',
                background: 'white',
                zIndex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
        },
    }
}

export default customTableStyles
