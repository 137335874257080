const chipsStyles = (theme) => ({
    container: {
        width: "fit-content",
        display: "flex",
        flexWrap: "nowrap",
        cursor: "default"
    },
    chipContainer: {
        borderRadius: "6px",
        background: "rgb(237 241 245)",
        padding: "4px 8px",
        display: "flex",
        alignItems: "center",
        marginRight: "4px",
        width: "fit-content"
    },
    chipText: {
        whiteSpace: "nowrap"
    },
    iconButton: {
        padding: 0,
        marginLeft: "4px"
    },
    statusIcon: {
        height: "20px",
        marginRight: "4px"
    }
})

export default chipsStyles
