import { useState, useEffect } from 'react'

const useScrollToggle = (scrollDownThreshold, scrollUpThreshold) => {
    const [scroll, setScroll] = useState(false)
    const [prevScrollY, setPrevScrollY] = useState(0)
    const handleScroll = () => {
        const currentScrollY = window.scrollY
        if (currentScrollY > scrollDownThreshold && currentScrollY > prevScrollY) {
            setScroll(true)
        } else if (currentScrollY <= scrollUpThreshold && currentScrollY < prevScrollY) {
            setScroll(false)
        }
        setPrevScrollY(currentScrollY)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [prevScrollY, scrollUpThreshold, scrollDownThreshold])

    return scroll
}

export default useScrollToggle
