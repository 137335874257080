import React, { useEffect, useRef } from 'react'
import Box from '@mui/material/Box'
import { FormattedMessage } from 'react-intl'
import Button from '@mui/material/Button'
import { observer } from 'mobx-react'
import { getStyles } from '../../utils/utils/commonUtils'

import lupe from '../../assets/images/common/lupeWhite.svg'
import filtersFormStyles from './styles/filtersFormStyles'
import { useTheme } from '@mui/material'
import { useState } from 'react'
import FormFilter from 'src/components/FormFIlter/FormFilter'
import mobileRequestsManagementStyles from '../../views/RequestsManagement/styles/mobileRequestManagmentStyles'
import { closeModalFn, openModalFn } from '../../utils/controllers/ModalController'

const FiltersForm = ({
    opened,
    onClose,
    filtersStore,
    onSearch,
    onClear,
    filtersData,
    setChip,
    filtersContainerHeight,
    scrollIsAlreadyLocked = false,
    width,
}) => {
    const classes = getStyles(filtersFormStyles, { length: filtersData.length, opened })
    const formRef = useRef(null)
    const theme = useTheme()
    const [selectsOpen, setSelectsOpen] = useState(false)

    

    useEffect(() => {
        const enterListener = (e) => {
            if (e.key === 'Enter') {
                handleSubmitAndClose(e)
            }
        }
        if (opened) {
            document.body.addEventListener('keydown', enterListener)
        } else {
            filtersStore.bringTheDataToTheSelectedFilters()
        }

        if (opened && !scrollIsAlreadyLocked) {
            const getScrollbarWidth = () => window.innerWidth - document.documentElement.clientWidth
            const scrollBarWidth = getScrollbarWidth()
            document.body.style.overflow = 'hidden'
            document.body.style.padding = `0 calc(${scrollBarWidth}px - (100vw - 100%)) 0 0`
        }
        if (!opened && !scrollIsAlreadyLocked) {
            document.body.removeAttribute('style')
        }

        return () => {
            document.body.removeEventListener('keydown', enterListener)
        }
    }, [opened])

    const handleSubmitAndClose = (e) => {
        setSelectsOpen(false)
        e.preventDefault()
        openModalFn['progress-backdrop']()
        onSearch().then(() => {
            
            onClose()
        })
    }
    
    return (
        <Box component={'form'} ref={formRef} sx={classes.root} onSubmit={handleSubmitAndClose}>
            <Box sx={classes.filters}>
                {filtersData.map((block, i) => {
                    return (
                        <Box sx={classes.filtersContainer} key={'block' + i}>
                            {block.map((data, i) => {
                                if (Array.isArray(data)) {
                                    return (
                                        <Box sx={classes.filtersBlock} key={'container' + data[0].propName + i}>
                                            {data.map((el) => {
                                                return (
                                                    <Box sx={classes.formFilterWrapper} key={el.propName}>
                                                        <FormFilter
                                                            data={el}
                                                            onSearch={onSearch}
                                                            filtersStore={filtersStore}
                                                            setChip={setChip}
                                                            opened={opened}
                                                            selectsOpen={selectsOpen}
                                                            setSelectsOpen={setSelectsOpen}
                                                            width={width}
                                                        />
                                                    </Box>
                                                )
                                            })}
                                        </Box>
                                    )
                                }

                                return (
                                    <Box
                                        key={data.propName}
                                        sx={{
                                            [theme.breakpoints.down('sm')]: {
                                                marginTop: i === 0 ? '16px' : '0',
                                            },
                                        }}
                                    >
                                        <FormFilter
                                            setChip={setChip}
                                            data={data}
                                            onSearch={onSearch}
                                            filtersStore={filtersStore}
                                            opened={opened}
                                            setSelectsOpen={setSelectsOpen}
                                            selectsOpen={selectsOpen}
                                            width={width}
                                           
                                        />
                                    </Box>
                                )
                            })}
                        </Box>
                    )
                })}
            </Box>

            <Box sx={classes.buttonsContainer}>
                <Button
                    variant={'contained'}
                    color={'primary'}
                    type='submit'
                    fullWidth={true}
                    sx={classes.actionButton}
                >
                    <img alt={'Поиск'} src={lupe} />
                    <FormattedMessage id={'Hайти'} />
                </Button>
                <Button
                    variant={'outlined'}
                    color={'primary'}
                    onClick={onClear}
                    sx={classes.actionButton}
                    fullWidth={true}
                >
                    <FormattedMessage id={'Сбросить'} />
                </Button>
            </Box>
        </Box>
    )
}

export default observer(FiltersForm)
